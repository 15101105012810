
p.lead {
    font-size: 1.25rem;
    font-family: $headings-font-family;
    font-style: italic;
}
.frame-type-text {
    p:last-of-type {
        &.lead {
            margin-bottom: 3rem;
        }
    }
}

ul.lead {
    font-size: 1.25rem;
    font-family: $headings-font-family;
    font-style: italic;
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem;
    li {
        padding-left: 1.5rem;
        margin-bottom: 0.5rem;
        &::before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            margin: 0 10px -0.3rem -1.5rem;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml;utf8,%3csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.59509 12L0 6.31185L1.39877 4.88981L5.59509 9.15592L14.6012 0L16 1.42204L5.59509 12Z' fill='%2328373C'/%3e%3c/svg%3e");
        }
    }

}

.frame {
    &.frame-mb-small {
        margin-bottom: 2rem;
    }

    &.frame-mb-large {
        margin-bottom: 4rem;
    }
}

// Content indent
.container-content {
    >.row>.col-12 {
        @include media-breakpoint-up(lg) {
            >.frame-type-text {
                margin-left: 8rem;
                margin-right: 8rem;
            }
        }
    }
}


// Default TYPO3 CE style classes
.ce-headline-center {
    text-align: center;
}