.container-content {
    padding-top: 4.5rem;
    padding-bottom: 3.5rem;
    &.container--beige {
        background-color: $color-honey;
    }
    &.container--grey {
        background-color: $color-light-grey;
    }
    &.container--brown {
        background-color: $color-light-brown;
    }
}