a.icon-phone,
a.icon-email,
a.icon-map,
a.icon-cal {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.75rem;
    &:before {
        content: '';
    }
}
a.icon-phone {
    &:before {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        background-image: url('data:image/svg+xml,%3csvg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M16.95 18C14.8 18 12.7043 17.5207 10.663 16.562C8.621 15.604 6.81267 14.3373 5.238 12.762C3.66267 11.1873 2.396 9.379 1.438 7.337C0.479334 5.29567 0 3.2 0 1.05C0 0.75 0.0999999 0.5 0.3 0.3C0.5 0.0999999 0.75 0 1.05 0H5.1C5.33333 0 5.54167 0.0749999 5.725 0.225C5.90833 0.375 6.01667 0.566667 6.05 0.8L6.7 4.3C6.73333 4.53333 6.72933 4.74567 6.688 4.937C6.646 5.129 6.55 5.3 6.4 5.45L3.975 7.9C4.675 9.1 5.55433 10.225 6.613 11.275C7.671 12.325 8.83333 13.2333 10.1 14L12.45 11.65C12.6 11.5 12.796 11.3873 13.038 11.312C13.2793 11.2373 13.5167 11.2167 13.75 11.25L17.2 11.95C17.4333 12 17.625 12.1123 17.775 12.287C17.925 12.4623 18 12.6667 18 12.9V16.95C18 17.25 17.9 17.5 17.7 17.7C17.5 17.9 17.25 18 16.95 18ZM3.025 6L4.675 4.35L4.25 2H2.025C2.10833 2.68333 2.225 3.35833 2.375 4.025C2.525 4.69167 2.74167 5.35 3.025 6ZM16 15.95V13.75L13.65 13.275L11.975 14.95C12.625 15.2333 13.2877 15.4583 13.963 15.625C14.6377 15.7917 15.3167 15.9 16 15.95Z" fill="%2328373C"/%3e%3c/svg%3e');
    }
}
a.icon-email {
    &:before {
        width: 20px;
        height: 16px;
        margin-right: 8px;
        background-image: url('data:image/svg+xml,%3csvg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.8043 0.979333 20 1.45 20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2ZM10 9L2 4V14H18V4L10 9ZM10 7L18 2H2L10 7ZM2 4V2V4V14V4Z" fill="%2328373C"/%3e%3c/svg%3e');
    }
}
a.icon-map {
    &:before {
        width: 14px;
        height: 20px;
        margin-right: 14px;
        background-image: url('data:image/svg+xml,%3csvg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z" fill="%2328373C"/%3e%3c/svg%3e');
    }
}
a.icon-cal {
    &:before {
        width: 20px;
        height: 22px;
        margin-right: 8px;
        background-image: url('data:image/svg+xml,%3csvg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"%3e%3cpath d="M18 2H17V0H15V2H5V0H3V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM18 20H2V9H18V20ZM18 7H2V4H18V7Z" fill="%23FFFAF0"/%3e%3c/svg%3e');
    }
}

a.btn {
    &.icon-phone,
    &.icon-email,
    &.icon-map,
    &.icon-cal {
        margin-bottom: 0;
    }
}
a.btn-block {
    width: 100%;
    &.icon-phone,
    &.icon-email,
    &.icon-map,
    &.icon-cal {
        justify-content: center;
    }
}