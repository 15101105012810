// https://gwfh.mranftl.com/fonts/roboto-condensed?subsets=latin
/* roboto-300italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/roboto-v30-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v30-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
    src: url('../fonts/feijoa-medium.woff2') format('woff2');
    font-family: 'Feijoa';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    src: url('../fonts/feijoa-medium-italic.woff2') format('woff2');
    font-family: 'Feijoa';
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    src: url('../fonts/feijoa-bold.woff2') format('woff2');
    font-family: 'Feijoa';
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
}