.frame-type-form_formframework {
    padding: 1rem;
    background-color: $color-light-grey;

    form {
        .form-group {
            margin-bottom: 1rem;
        }
    }
}
