footer {
    padding: 3rem 0;
    background-color: $color-anthracite;
    color: $color-honey;

    h6 {
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
    }

    a {
        color: $color-honey;
        text-decoration: none;
    }
    .social-memberships {
        ul.member-logos {
            padding-top: 2rem;
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: inline-block;
                padding-right: 1.875rem;
                padding-bottom: 1.25rem;
            }

            @include media-breakpoint-up(md) {
                padding-top: 0;
                text-align: right;
                li {
                    padding-right: 0;
                    padding-left: 1.875rem;
                }
            }

        }
    }
    .partner-links {
        display: none;
        @include media-breakpoint-up(md) {
            display: block;
        }
        a {
            margin: 0 0.25rem;
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
    .footer-bottom {
        nav {
            margin-top: 2rem;
            border-top: 1px solid $grey-lighter;
            padding-top: 2rem;

            ul.nav {
                li {
                    .nav-link {
                        padding: 0 1rem;
                        color: $color-honey;
                        text-decoration: none;
                        cursor: pointer;
                    }
                    &:first-of-type {
                        .nav-link {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}