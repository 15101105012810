$color-honey: #FFFAF0;
$color-light-grey: #F7F7F4;
$color-light-brown: #DBCEAC;
$color-green: #828C6E;
$color-anthracite: #28373C;

$accordion-focus-box-shadow: 0 1rem 1rem -1rem rgba(223, 0, 122, 0.25);

// -----------------------------------------
// bootstrap variables

// Color system (6)
$white: #fff;
$grey-lighter: #E9E9E9;
$black: #212121;

$primary: $color-anthracite;

// Options (366)
$enable-dark-mode: false;

// Body (433)
$body-color:     $black;
$body-bg:        $white;

// Links (449)
$link-decoration: none;

// Components (528)
$border-radius: 4px;

// Typography (600)
$font-family-sans-serif: 'Roboto', sans-serif;
$font-weight-bolder: 700;

$headings-font-family: Feijoa, serif;
$headings-font-weight: 400;
$headings-line-height: 1.5;

$lead-font-size: 18px;
$lead-font-weight: 400;

// Buttons + Forms (784)
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 1.5rem;

// Accordion (1363)
$accordion-border-width:            0;
$accordion-body-padding-x:          0;
$accordion-body-padding-y:          0.25rem;
$accordion-button-padding-x:        0;
$accordion-button-active-bg:        $body-bg;
$accordion-button-active-color:     $body-color;
//$accordion-button-focus-box-shadow: $accordion-focus-box-shadow;
$accordion-button-focus-box-shadow: none;
