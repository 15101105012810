.accordion-item {
    border-top: none;
    border-bottom: none;

    .accordion-header {
        border-top: 1px solid $color-anthracite;
    }

    .accordion-button {
        font-family: $font-family-sans-serif;
        font-size: 1.125rem;
        font-weight: $font-weight-normal;
        text-transform: uppercase
    }
}