
.small-highlight-box {
    background-color: $color-light-brown;
    padding: 2.25rem;
    margin-bottom: 2rem;
    border-radius: $border-radius;

    ul {
        margin-bottom: 0;
    }

    ul + p {
        margin-top: 1rem;
    }

    p:last-of-type {
        margin-bottom: 0;
    }
}

// TextMedia CE Styles
.frame-type-textmedia {
    position: relative;

    .ce-textpic {
        figure {
            margin-bottom: 0;
        }
        img {
            max-width: 100%;
            height: auto;
        }

        &.ce-intext {
            margin-bottom: 5rem;
            &.ce-left {
                display: grid;
                grid-template-areas:
                    "image"
                    "content";
                column-gap: 1.5rem;
                .ce-gallery {
                    grid-area: image;
                }
                .ce-bodytext {
                    grid-area: content;
                    z-index: 1;
                    .ce-bodytext-inner {
                        padding: 1rem;
                        background-color: $white;
                    }
                }
            }
            &.ce-right {
                display: grid;
                grid-template-areas:
                    "image"
                    "content";
                column-gap: 1.5rem;
                .ce-gallery {
                    grid-area: image;
                }
                .ce-bodytext {
                    grid-area: content;
                    z-index: 1;
                    .ce-bodytext-inner {
                        padding: 1rem;
                        background-color: $white;
                    }
                }
            }
            @include media-breakpoint-up(md) {
                &.ce-left {
                    grid-template-columns: 5fr 7fr;
                    grid-template-areas: "image content";
                    .ce-bodytext {
                        transform: translateX(-5rem) translateY(2.25rem);
                    }
                }
                &.ce-right {
                    grid-template-columns: 7fr 5fr;
                    grid-template-areas: "content image";
                    .ce-bodytext {
                        transform: translateX(5rem) translateY(2.25rem);
                    }
                }

            }
            @include media-breakpoint-up(lg) {
                &.ce-left {
                    grid-template-columns: 7fr 5fr;
                }
                &.ce-right {
                    grid-template-columns: 5fr 7fr;
                }
            }
        }

        &.ce-above {
            display: grid;
            grid-template-areas:
              "image"
              "content";
            gap: 0;
            .ce-gallery {
                grid-area: image;
            }
            .ce-bodytext {
                grid-area: content;
                padding: 1rem;
                background-color: $white;
                @include media-breakpoint-up(md) {
                    transform: translateY(-1.875rem);
                    z-index: 1;
                    width: calc(100% - 2.25rem);
                }
            }
        }
    }

    .card {
        &.card-image-overlay {
            position: relative;
            .card-body {
                position: absolute;
                top: 50%;
                left: 2rem;
                background-color: #fff;
                padding: 1rem;
                width: auto;
                display: inline-block;
                transform: translateY(-50%);
                max-width: 40%;
            }
        }
    }
}
